.video-title {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
}

.video-container {
  position: relative;
}

.proceed-button-container {
    padding: 2.5rem 1rem;
}
.react-player .react-player__play-icon {
    display: none !important;
}

video::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }

  /* For Firefox */video::-moz-media-controls-play-button {
    display: none !important;
  }

  /* For other browsers that might support this */video::--media-controls-play-button {
    display: none !important;
  }

  /* For Safari */
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

/* For Android browsers */
video::-internal-media-controls-play-button {
  display: none !important;
}


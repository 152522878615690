.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* .app-font-global{
  font-size:'2.7rem';
  font-weight:"300" ;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container {
 font-size: 4rem;
  line-height:1;
   font-weight:300;
   color: black;
}

@media (min-width: 200px) and (max-width: 400px){
  .container {
    font-size: 2.1rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }

  .quiz-info {
    font-size: 2.83rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .container {
    font-size: 2.6rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }
  .quiz-info {
    font-size: 3.1rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }

}

@media (min-width: 600px) and (max-width: 800px)  {
  .container {
    font-size: 2.1rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }

}
@media (min-width: 800px) and (max-width: 1024px)  {
  .container {
    font-size: 2.4rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }
  .quiz-info {
    font-size: 3.4rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }
}
@media (min-width: 1024px) and (max-width: 1400px) {
  .container {
    font-size: 2.4rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .container {
    font-size: 2.7rem;
    line-height:1;
    color: #000000;
     font-weight:300;
  }
}

.home-container {
    background-color: red;
    height: 100vh;
    width: 100%;
    background-image: url('../asset/home-section/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px; 
}

.breef-heading {
    display: flex;
    justify-content: space-between;
}

.brief-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 5px;
    cursor: pointer;

}
.center-row {
    display: flex;
    justify-content: center;
}


.overflow-auto {
    overflow: auto !important;
    
}

.breef-heading {
    display: flex;
    justify-content: space-between;
}



.proceed-button-container {
    margin: 20px;
}




.appbar-container {
    position: relative;
}

.drawer-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: 'transparent';
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.drawer-paper {
    width: 70%; /* Adjust as needed */
}

.card-header {
    display: flex;
    align-items: center;
    padding: 15px 10px;
}

.card-title {
    padding-left: 10px;
    font-weight: 500;
}

.card-number {
    padding-left: 15px;
    color: #EB6608;
    font-size: 2rem;
}

.ranking-card {
    background-color: white;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 10rem;
    border-radius: 5px;
}

.card-number {
    font-size: 2rem;
    font-weight: bold;
}

.home-container::-webkit-scrollbar {
    width: 8px;
}

.home-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.home-container::-webkit-scrollbar-thumb {
    background-color: #EB6608;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.card-header-left {
    display: flex;
}
.card-heading {
    padding-left: 15px;
}

.right-side {
    padding-right: 20px;
    padding-top: 20px;
}

.card-background {
    position: absolute;
    bottom: 0px;
    height: 3rem;
    background-image: url('../asset/home-section/card-background.png');
    background-size: cover;
    background-position: center;
    width: 100%;
}
.text-center {
    font-size: 3.3rem;
    line-height:1;
    color: #595B61;
     font-weight:800;
     position: absolute;
     bottom: 7%;
     left: 50%;
     transform: translate(-50%, -50%);
  }
  .adjust-height{
    min-height: 900px;
  }

  .home-container2 {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #f0f0f0; /* Optional: adds background color where image doesn't cover */
  }
  .centered-text {
    position: absolute;
    z-index: 1;
    font-size: 2rem;
    font-family: Arial, sans-serif;
    text-align: center;
    top: 78%;
    color: #595B61;
    /* padding: 20px;Semi-transparent background for better readability */
  }     

@media (min-width: 200px) and (max-width: 320px){
    .text-center {
      font-size: 1.6rem;
      line-height:1;
      color: #595B61;
       font-weight:800;
    }

    .adjust-height{
        min-height: 900px;
      }}
    @media (min-width: 320px) and (max-width: 400px){
        .text-center {
          font-size: 3.3rem;
          line-height:1;
          position: absolute;
          bottom: 7%;
          left: 50%;
          color: #595B61;
           font-weight:800;
        }
        .adjust-height{
            min-height: 900px;
          }
          /* .centered-text {
            font-size: 1.8rem;
            top:74%;
          } */
    }

    @media(min-width: 820px) and (max-width:1400px){
       .centered-text {

        top:79%;
      }
      .text-center{
        bottom: 9%;
      }
    }
    @media(min-width: 1280px) and (max-width:1450px){
      .centered-text {

       top:79%;
     }
     .text-center{
       bottom: 7%;
     }
   }
    @media(min-width: 1450px) and (max-width:1800px){
      .centered-text {

       top:79%;
     }
     .text-center{
       bottom: 2%;
     }
   }
    @media(min-width: 1800px) and (max-width:2000px){
    
     .text-center{
       bottom: 5%;
     }
   }
   @media(min-width: 2400px) and (max-width:2500px){
    
    .text-center{
      bottom: 5%;
    }
  }
        @media (min-width: 1024px) and (max-width: 1280px){
            .text-center {
              font-size: 3.1rem;
              line-height:1;
              position: absolute;
              bottom: 7%;
              left: 50%;
              color: #595B61;
               font-weight:800;

            }
            .adjust-height{
              min-height: 1030px;
            }
            .centered-text {
           
              top: 79%;
            }
         
        }

            